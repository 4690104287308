import React, { useContext, useEffect, useState } from 'react';
import axiosClient from '../../library/Service/axiosClient';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';
import Swal from 'sweetalert2';

const WidgetPreview = () => {
    const { id } = useParams<{ id: string }>(); // Get the `id` from the UR
    const loaderData = useLoaderData() as any;
    let wgdata = loaderData?.data?.wedgitData?.wgdata;
    let wginfodata = loaderData?.data?.wedgitData?.wginfodata?.data;
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
      } as any);
    
    const [isPublished, setIsPublished] = useState(wgdata?.is_published);

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { loaderData },
        });
    }, [loaderData]);
    
    const handlePublish = async () => {
        try {
          const response = await axiosClient.get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/publishwidget/${id}`);
          console.log('Publish successful:', response.data); // Log success message or handle response data
            Swal.fire({
                title: "Done!",
                text: "Widget has been published successfully..!",
                icon: "success"
              });
          setIsPublished(1);
          // Optionally, you can show a success message or update UI state here
        } catch (error) {
          console.error('Error publishing widget:'); // Log error message
          // Handle errors gracefully, e.g., display an error message to the user
        }
      };

const iframeCode = `
<iframe 
    src="${process.env.REACT_APP_APP_BASE_URL}/widget/${id}"
    title="${wgdata?.name}" 
    width="100%" 
    height="600px" 
    loading="lazy"
    description="${wginfodata?.widgetData?.largeHeading ?? wginfodata?.widgetData?.heading ?? wgdata?.large_heading ?? wgdata?.name}"
    style="border: none; vertical-align: top"
></iframe>
`;

const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeCode)
        .then(() => {
            Swal.fire({
                title: "Copied successfully!",
                text: "You can now paste this iframe code wherever you need..!",
                icon: "success"
              });
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        });
};

return (
        <>
            <div className='main flex-1 bg-white'>
                <div className="max-1140 mx-auto pt-5">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                        <h3 className="font-30 font-600 mb-0 block-title position-relative">
                            Manage Widgets For Alumni <i className="icon-right-arrow font-16 position-relative me-1"></i> <span>{wgdata?.name}</span>
                        </h3>
                    </div>
                </div>

                <div className="py-5 bg-yellow-FFFAE5">
                    <div className="max-1140 mx-auto">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="embed-wrap">
                                    {isPublished == 1 && (
                                        <div className="row g-3 justify-content-between align-items-center">
                                            <div className="col-12">
                                                <h3 className="mb-0 font-18">Embed this post</h3>
                                            </div>
                                            <div className="col-12">
                                                <hr className="m-0 dotted" />
                                            </div>
                                            <div className="col-auto">
                                                <div className="api-link-title">
                                                    Copy and paste embed code on your site
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="link-url">
                                                    <button onClick={copyToClipboard} className="btn btn-configure">
                                                        Copy Code
                                                    </button>
                                                    <i id='copy_icon' onClick={copyToClipboard}></i>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <p className="text-dark-blue font-500"> {iframeCode}</p>
                                            </div>
                                        </div>
                                    )}
                                    {isPublished != 1 && (
                                        <span>The widget has not been published yet, Iframe code will be displayed once published.</span>
                                    )}
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>

                <div className="max-1140 mx-auto pt-5">
                    <div className="d-flex justify-content-center align-items-center pb-3">
                        <h3 className="font-30 font-600 mb-0 block-title position-relative d-flex align-items-center">
                            <i className="icon-eye-black me-2"></i> PREVIEW</h3>         
                    </div>
                </div>

                <div>
                    <iframe 
                        src={`${process.env.REACT_APP_APP_BASE_URL}/widget/${id}?callFrom=preview`}
                        title={wgdata?.name}
                        width="100%" 
                        height="600px" 
                        loading="lazy"
                        data-description={wginfodata?.widgetData?.largeHeading ?? wginfodata?.widgetData?.heading ?? wgdata?.large_heading}
                        style={{ border: 'none', verticalAlign: 'top' }}
                    ></iframe>
                </div>

                <div className="max-1140 py-5 overflow-hidden mx-auto">
                    <div className="row g-4">
                        <div className="col-sm-12">
                            <div className="action_btns mt-0 max-100per">
                                {isPublished != 1 && (
                                    <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5"  onClick={handlePublish}><span className="px-3">Publish</span></button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WidgetPreview;
