import { getAssociativeArray } from '../../library/Core/SmartContext';
import axiosClient from '../../library/Service/axiosClient';

class RenderWidgetService {
    /**
     * Fetches widget data and domain data based on the provided parameters and constructs a state object.
     *
     * @remarks
     * This function is responsible for fetching widget data and domain data, and constructing a state object
     * based on the provided parameters. It uses Promises to handle asynchronous operations.
     *
     * @param params - An object containing the parameters required for fetching widget data.
     * @param params.id - The unique identifier for the widget data to retrieve.
     *
     * @returns A Promise that resolves to the constructed state object.
     * @returns The state object contains the fetched widget data and domain data, as well as internal data.
     *
     * @example
     * ```typescript
     * const params = { id: '152d487b-0248-4bd3-81ca-3db2e4c38604' };
     * const state = await RenderWidgetService.pageLoader(params);
     * console.log(state);
     * ```
     */
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        let internal: any = {};
        return Promise.all([
            this.getWidgetData(params.id),
        ]).then(async (values) => {
            state.data = values[0].data ?? [];
            let domain_category_code = 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI';

            switch (state?.data?.wgdata?.code) {
                case 'top_study_country':
                case 'top_colleges':
                case 'current_location_country':
                    domain_category_code = 'COUNTRY_CODE';
                    break;

                case 'top_companies':
                case 'top_industries':
                    domain_category_code = 'INDUSTRY_CODE';
                    break;

                case 'directory_stats':
                    domain_category_code = 'DIRECTORY_STATS_METRIC_TYPE';
                    break;
                
                case 'alumni_occupation':
                    domain_category_code = 'OCCUPATION_CODE';
                    break;
            }

            let domainData: any = await this.getDomainData(domain_category_code)

            let domainDataAssoc = getAssociativeArray(domainData?.data, 'code') ?? [];
            internal[state.data?.wgdata?.code] = domainDataAssoc;

            state.internal = internal;
            return Promise.resolve(state);
        });
    }

    /**
     * Retrieves widget data based on the provided ID.
     *
     * This function sends a GET request to the Widget API server to fetch widget data associated with the given ID.
     *
     * @param id - The unique identifier for the widget data to retrieve.
     *
     * @returns A Promise that resolves to the fetched widget data.
     *
     * @example
     * ```typescript
     * const widgetId = '152d487b-0248-4bd3-81ca-3db2e4c38604';
     * const widgetData = await RenderWidgetService.getWidgetData(widgetId);
     * console.log(widgetData);
     * ```
     */
    static getWidgetData(id: string) {
        return axiosClient.get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidget/${id}`);
    }

    /**
     * Retrieves domain data based on the provided code.
     *
     * @remarks
     * This function sends a GET request to the Widget API server to fetch domain data associated with the given code.
     *
     * @param code - The unique identifier for the domain data to retrieve.
     *
     * @returns A Promise that resolves to the fetched domain data.
     *
     * @example
     * ```typescript
     * const domainCode = 'COUNTRY_CODE';
     * const domainData = await RenderWidgetService.getDomainData(domainCode);
     * console.log(domainData);
     * ```
     */
    static getDomainData(code: string) {
        return axiosClient.get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-domain-data/${code}`);
    }
}

export default RenderWidgetService;
