import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { isEmpty } from '../../library/Core/SmartContext';

interface MetricData {
    value: number | string;
    rowUUID: string;
    metricType: string;
    metricEntity2: string;
}

interface WidgetData {
    data: {
        instWiseListData: any;
        wgdata: any;
        wginfodata: {
            data: {
                metricData: MetricData[];
                widgetData: any;
                popupData: any;
            }
        }
    };
    internal: any;
}

interface GuidingAlumniListProps {
    widgetData: WidgetData;
}

const GuidingAlumniList: React.FC<GuidingAlumniListProps> = ({ widgetData }) => {
    const data = widgetData?.data?.wgdata;
    const alumniData: any = widgetData?.data?.instWiseListData;

    // OwlCarousel options
    const options = {
        margin: 10,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };

    return (
        <>
            <h4 className="font-30 text-center text-white mb-2">
                {data?.large_heading}
            </h4>
            <p className="text-center text-white pb-3">
                {data?.medium_heading}̥̥
            </p>
            <OwlCarousel className="owl-theme fourcardslider"  {...options}>
                {alumniData.map((alum: any, index: any) => (
                    <div className="item" key={index}>
                        <div className="guiding_alumni_block h-100">
                            <div className="guiding_alumni_top">
                                <div className="guiding_alumni_profile_img">
                                    <img
                                        src={alum?.profile_pic || `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`}
                                        width="80"
                                        alt={alum?.user_name}
                                        onError={(e) => {
                                            e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`;
                                        }}
                                    />
                                </div>
                                <div className="guiding_alumni_profile_name">{alum?.user_name}</div>
                            </div>
                            <div className="guiding_alumni_college_cource">
                                <ul>
                                    <li>
                                        <i className="college"></i>
                                        <span>
                                            {alum?.school_name}
                                            {!isEmpty(alum?.user_program?.trim()) && `, ${alum?.user_program?.trim()}`}
                                            {alum?.user_year > 0 && `, ${alum?.user_year}`}
                                        </span>
                                    </li>

                                    {alum?.select_ug_college == 1 && (
                                        <li>
                                            <i className="tool"></i> <span>{alum?.joined_ug_course}</span>
                                        </li>
                                    )}

                                    {alum?.select_ug_college == 1 && (
                                        <li>
                                            <i className="cap icon-yellow"></i> <span>{alum?.joined_ug_college}</span>
                                        </li>
                                    )}

                                    {alum?.select_pg_college == 1 && (
                                        <li>
                                            <i className="tool"></i> <span>{alum?.joined_pg_course}</span>
                                        </li>
                                    )}

                                    {alum?.select_pg_college == 1 && (
                                        <li>
                                            <i className="cap icon-yellow"></i> <span>{alum?.joined_pg_college}</span>
                                        </li>
                                    )}

                                    {alum?.select_pg_company == 1 && (
                                        <li>
                                            <i className="brifecase"></i>
                                            <span>
                                                {alum?.current_designation}
                                                {!isEmpty(alum?.current_designation) && !isEmpty(alum?.current_company) && ` at `}
                                                {alum?.current_company}
                                            </span>
                                        </li>
                                    )}

                                    {/* {alum?.nationality && (
                                        <li>
                                            <i className="icon-location-pin text-yellow me-2 mt-1 ms-1"></i> <span>{alum?.nationality}</span>
                                        </li>
                                    )} */}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </>
    );
};

export default GuidingAlumniList;
