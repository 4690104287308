import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import '../../styles/css/revamp_style.css'
import TopCareerHorizontal from './TopCareerHorizontal';
import TopCareerVertical from './TopCareerVertical';
import TopCareerPieChart from './TopCareerPieChart';
import TopCompaniesVertical from './TopCompaniesVertical';
import TopCompaniesHorizontal from './TopCompaniesHorizontal';
import DirectoryStats from './DirectoryStats';
import GuidingAlumniList from './GuidingAlumniList';
import AlumniSpeakList from './AlumniSpeakList';
import NotableAlumniList from './NotableAlumniList';
import EclecticCareerList from './EclecticCareer';
import { initTooltip } from '../../library/Core/SmartContext';
import AppealVideo from './AppealVideo';
import AlumniSpotlight from './AlumniSpotlight';
import CoverBanner from './CoverBanner';

const RenderWidget = () => {
    const [params, setParams]: any = useState({});
    const loaderData = useLoaderData() as any;
    const defaultWidget = <div>Widget not found</div>;
    const [widgetClass, setWidgetClass] = useState('');
    const [fullWidth, setFullWidth] = useState(false);

    let wgdata = loaderData?.data?.wgdata;
    let wginfodata = loaderData?.data?.wginfodata;

    const widgetCode = wgdata?.code;
    const chartType = wginfodata?.data?.widgetData?.chartType;
    const moreCardsClass = 'more-cards';
    let gridLayoutLength = 4;
    let moreCardsFlag = false;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const callFrom = urlParams.get('callFrom');
        setParams({ callFrom });
        initTooltip();
    }, []);

    const renderWidget = () => {
        if (params?.callFrom != 'preview' && wgdata?.is_published == 0) {
            return defaultWidget;
        }

        let widget;
        let customClass: any = 'bg-gray-EFEFEF';
        let fullWidthFlag = false;

        switch (widgetCode) {
            case 'top_careers':
            case 'top_study_country':
            case 'current_location_country':
            case 'alumni_occupation':
                if (chartType === 'vertical_bars') {
                    widget = <TopCareerVertical widgetData={loaderData} />;
                } else if (chartType === 'horizontal_bars') {
                    widget = <TopCareerHorizontal widgetData={loaderData} />;
                } else {
                    widget = <TopCareerPieChart widgetData={loaderData} />;
                }
                break;

            case 'top_companies':
            case 'top_colleges':
                if (wginfodata?.data?.widgetData?.menuTabs == 'vertical') {
                    widget = <TopCompaniesVertical widgetData={loaderData} />;
                } else {
                    widget = <TopCompaniesHorizontal widgetData={loaderData} />;
                }
                break;

            case 'directory_stats':
                widget = <DirectoryStats widgetData={loaderData} />;
                break;

            case 'guiding_alumni_mentors':
                widget = <GuidingAlumniList widgetData={loaderData} />;
                // customClass = 'bg-blue-164F88 owl-carousel-cards';
                customClass = 'bg-blue-164F88';
                break;

            case 'notable_alumni_mentors':
                widget = <NotableAlumniList widgetData={loaderData} />;
                break;

            case 'alumni-speak':
                widget = <AlumniSpeakList widgetData={loaderData} />;
                gridLayoutLength = loaderData?.data?.wgdata?.grid_layout;
                break;
            case 'eclectic_career_alumni':
            case 'entrepreneur_alumni':
                widget = <EclecticCareerList widgetData={loaderData} />;
                customClass = 'bg-blue-164F88';
            break;
                
            case 'top_industries':
                if (chartType === 'vertical_bars') {
                    widget = <TopCareerVertical widgetData={loaderData} colorClass="lite-red" slant={true} />;
                } else if (chartType === 'horizontal_bars') {
                    widget = <TopCareerHorizontal widgetData={loaderData} />;
                } else {
                    widget = <TopCareerPieChart widgetData={loaderData} />;
                }
                break;
            
            case 'appeal_video':
                widget = <AppealVideo widgetData={loaderData} />;
                break;

            case 'spotlight':
                customClass = 'bg-pink-FFEFF1';
                widget = <AlumniSpotlight widgetData={loaderData} />;
                break;
        
            case 'cover_banner':
                fullWidthFlag = true;
                widget = <CoverBanner widgetData={loaderData} />;
                break;

            // Add more cases here for other widget codes and types
            default:
                widget = defaultWidget;
        }

        moreCardsFlag = loaderData?.data?.instWiseListData?.length > gridLayoutLength;

        if (customClass !== widgetClass) {
            setWidgetClass(customClass);
        }

        if (fullWidthFlag !== fullWidth) {
            setFullWidth(fullWidthFlag);
        }

        return widget;
    };
    
    return (
        <>
            <div className={` ${widgetClass} position-relative`}>
                <div className={`${!fullWidth ? 'max-1140 py-5' : ''} mx-auto`}>
                    {renderWidget()}
                    {wgdata?.membership != 'paid' && (
                        <div className={`d-flex justify-content-end mt-3 uni-logo-small position-absolute bottom-20 right-20 z-1 ${moreCardsFlag ? moreCardsClass : ''}`}><img data-bs-toggle="tooltip" title="Powered by Univariety" src={`${process.env.REACT_APP_IMAGE_BASEPATH}/university-logo-shape.png`} alt="" /></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default RenderWidget;
